import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const EssayTimeline = () => (

  <Layout>
    <SEO title="NATIONAL STUDENT ESSAY COMPETITION TIMELINE" />

    <h1>NATIONAL STUDENT ESSAY COMPETITION TIMELINE</h1>

    <div><span className="date">JANUARY 20</span>
      <p>Invite newspapers to support student voices speaking for freedom of the press</p>
    </div>


    <div><span className="date">FEBRUARY 17</span>
      <p>Press release and call-for-entries templates sent to participating newspapers</p>
    </div>

    <div><span className="date">EARLY MARCH</span>
      <p>Call-for-entries posted in participating newspapers</p>
    </div>

    <div><span className="date">APRIL  20</span>
      <p>Participating newspapers begin judging their local entries</p>
    </div>

    <div><span className="date">MAY 25</span> 
      <p>Newspaper judging concludes</p>
      <p>Winning essays submitted to xxxxxxx</p>
    </div>

    <div><span className="date">JUNE 1</span>
      <p>National and local announcement of winners (become semi-finalists)<br />(Announcement/press release templates sent to participating newspapers)</p>
    </div>

    <div><span className="date">JUNE 8</span>
      <p>Semi-finalist judging</p>
    </div>

    <div><span className="date">JUNE 15</span>
      <p>Semi-finalist judging of the winning essays at the local level</p>
    </div>

    <div><span className="date">JULY 20</span>
      <p>Announce semi-finalist winners</p>
    </div>

    <div><span className="date">JULY 27</span> 
      <p>Begin judging finalists</p>
    </div>

    <div><span className="date">AUGUST 24</span>
      <p>Announce winners except for overall winner to be determined by highest ranking among the three categories.</p>
      <p>All winners announced at 2020 Fall Lecture, “In Praise of Independent Journalism,” sponsored by the McCarthey Family Foundation</p>
    </div>

  </Layout>

)

export default EssayTimeline